<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Parâmetro</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row style="height: 100px">
                <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <label>Descrição</label>
                    <v-text-field
                        v-model="parametroRequest.descricao" 
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            
            <br />

            <ActionButtons
                :request="parametroRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons
        },

        data: () => ({

            validForm: true,

            parametroRequest: {
                id: 0,
                descricao: "",
                listParametroItem: []
            },

            loading: false,
            
            validations: {
                required: required,
            }
        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("parametroModule/GetById", this.id);

                    if (response.success) {
                        this.parametroRequest = response.result;

                        if (this.parametroRequest.listParametroItem.length == 0) {
                            this.addNewItem();
                        }
                    }

                }
                else {
                    this.addNewItem();
                }
            },

            async cancel() {
                this.$router.push({ path: "/produto/parametroList" });
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Aviso!", "Há inconsistências no formulário. Por favor revise!");
                    return false;
                }
                else {

                    // if (await this.validateParametroItem()) {
                        this.loading = true;
                        this.showLoading();

                        this.parametroRequest.id = this.id;

                        const result = await this.$store.dispatch("parametroModule/CreateUpdate", this.parametroRequest);

                        if (result.success === true) {
                            this.showToast("success", "Sucesso!", result.message);
                            this.cancel();
                        }
                        else {
                            this.showToast("error", "Aviso!", result.message);
                        }

                        this.loading = false;
                        this.hideLoading();
                    // }
                }
            }
        },

        created() {
            this.showLoading();

            this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>
    .colButtonList {
        padding-top: 20px;    
    }

    .colButtonList2 {
        margin-top: 8px;
    }

    @media only screen and (max-width: 647px) {
        .colButtonList {
            margin-top: -30px;
            display: flex;
            align-items: center;
        }

        .colButtonList2 {
             margin-top: -8px;
        }

        .btnActions {
            margin-bottom: 5px;
        }
    }
</style>